

<template>
    <div class="bg-gray-200 p-2 min-h-screen flex justify-center">
      <!-- Main Container with Fixed Width -->
      <div class=" bg-white p-2 max-w-screen-lg w-full h-full rounded-lg">
        <!-- Dashboard Grid Layout -->
        <div class="grid grid-cols-1 ">
          
					<EnergyPricesChart/>
      </div>
    </div>
  </div>
</template>
  
<script>
  import EnergyPricesChart from '@/components/EnergyPricesChart.vue';
  import PlantInformation from '@/components/PlantInformation.vue';

  export default {
  
	components:{
		EnergyPricesChart,
	}
  };
  
</script>