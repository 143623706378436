<!--
    Copyright: Simon Gomzi
    Version: V0.0
    Status: In testing
    Code Testing Process: 
        - Functionality and Error testing in Opera browser/console.
        - Visual and functional testing on Android/IOS, 
        - Visual and functional testing in Chrome, Firefox, Edge. 
        - Testing without connection to server.

    Dependencies
    ------------
        - Tailwind
        - Apexcharts 

    Outputs
    -------
        - Display to browser

    General
    -------
    Component fetches daily, weekly and monthly energy prices from EnergyCharts API. 
    Prices are fetched from the Energy Charts platform. 
    For more information about the Energy Charts API, visit: 
    https://api.energy-charts.info/

    Parameters (globals)
    ----------

    Changelog      
    ---------

    TODO: 
		- Implement custom X axis ticks
		- Implement custom tooltip
		- Implement loading for better UX
       
    FIXME: 
        - Fetch error handling.
-->





<template>
  <div class="flex justify-between items-center px-2">
    <h2 class="font-bold text-2xl mb-4 text-gray-900">Cena na borzi</h2>
		<button @click="expandContent" class="text-gray-900 font-medium px-2 py-1 hidden lg:flex">
      <span class="material-symbols-outlined"> open_in_full </span>
    </button>
  </div>

  <div class="flex justify-between items-center relative font-normal px-2">
    <div class="flex space-x-1">

      <button @click="decreaseDate" class="bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-1 rounded font-medium w-15"><</button>

			<!--Display of the selected date range-->
      <div class="bg-yellow-400 text-white px-2 py-1 rounded font-medium w-15">
        {{displayStartDate}} - {{displayEndDate}}
      </div>

      <button @click="increaseDate" v-if="increaseBtnVisibiliy" class="bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-1 rounded font-medium w-15">></button>
      <button @click="showTodayDate" class="bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-1 rounded font-medium w-15">Danes</button>
      <button @click="showWeekDate" class="bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-1 rounded font-medium w-15 hidden sm:flex">1T</button>
      <button @click="showMonthDate" class="bg-yellow-400 hover:bg-yellow-500 text-white px-2 py-1 rounded font-medium w-15 hidden sm:flex">1M</button>
    </div>
  </div>

  <div id="chart"></div>

  <div class="font-light text-right px-2">Sync: {{displaySyncState}}</div>
</template>





<script>


export default {

name: "SteplineChart",


data() {
	return {
		parsedSelectedPrice: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		parsedSelectedDate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		xAxisTicks: [],
		selectMode: 'Today',
		selectStartDate: '',
		selectEndDate: '',
		displayStartDate: '12.11',
		displayEndDate: '12.11.2024',
		displaySyncState: 'loading...',
		responsiveLineWidth: 3,
		regularLineWidth: 3,
		mediumLineWidth: 2,
		smallLineWidth: 1,
	}
},


  


methods: {


/** SteplineChart()
 *
 * Functionality:
 * Render and displays the stepline chart based on input data.
 *
 * Input:
 * - parsedSelectedPrice (arr): Hourly prices for the selected range (Y-axis).
 * - parsedSelectedDate (arr): Array of hour timestamps for each price.
 * - responsiveLineWidth (num): Chart line width based on the selected range of shown data.
 *
 * Output:
 * - Browser chart rendering.
 * 
 * Trigger:
 * - On component load.
 * - handleFetchedPrices().
 * 
 * TO DO:
 * - 
 */
	SteplineChart() {
	const options = {
		
		chart: {
			type: "line",
			height: '',
			width: '100%',
			toolbar: { show: false },	
			zoom: {
				enabled: false,
				zoomedArea: { enabled: false, },
		},
		},

		colors: ['#364E66'],	
		dataLabels: { enabled: false },
		markers: { hover: { sizeOffset: 0 } },

		series: [
			{
			name: 'EUR/MWh',
			data: this.parsedSelectedPrice,
			},
		],

		stroke: {
			curve: "stepline",

			width: this.responsiveLineWidth,
		},

		xaxis: {
			tickAmount: undefined,
			overwriteCategories: this.customXAxisTicks(),
			tickPlacement: 'on',
			labels: { rotate: 0, hideOverlappingLabels: true,}, //If tickAmmount is defined, hideOverlappingLables does not work!!!
			padding: 10,
		},

		yaxis: [
		{
			labels: {
			formatter: function (val) {
				return val.toFixed(0);
			},
			},
		},
		],
		tooltip:{ 
			enabled: true,
			custom: this.customTooltip,
			},
		
	};


		// Deletion of the old chart
		if (this.chart) {
			this.chart.destroy(); 
		}

		//Rendering of the new chart
		this.chart = new ApexCharts(document.querySelector("#chart"), options);
		this.chart.render();
	},



/** showTodayDate()
 *
 * Functionality:
 * - The function sets the selectStartDate and selectEndDate to todays date in 
 *   a YYYY-MM-DD format to prepare it for the fetch function that it calls at the end.
 * - Sets the Chart line width and sets the selected mode
 *
 * Input:

 * 
 * Output:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - selectMode (str).
 * - responsiveLineWidth (num).
 * - fetchEnergyPrices().
 * 
 * Trigger:
 * - On component load.
 * - Button press 'showTodayDate'.
 *
 * TO DO:
 * 
 */
	showTodayDate() {

		const todayDate = new Date();

		this.selectStartDate = todayDate.toISOString().split('T')[0];
		this.selectEndDate = todayDate.toISOString().split('T')[0];
		
		this.selectMode = 'Today';
		this.responsiveLineWidth = this.regularLineWidth;

		this.fetchEnergyPrices();

	},



/** showWeekDate()
 *
 * Functionality:
 * - The function sets the selectStartDate to 6 days before todays date and selectEndDate 
 *   to todays date in  a YYYY-MM-DD format to prepare it for the fetch function that 
 *   it calls at the end.
 * - Sets the Chart line width and sets the selected mode
 *
 * Input:

 * 
 * Output:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - selectMode (str).
 * - responsiveLineWidth (num).
 * - fetchEnergyPrices().
 * 
 * Trigger:
 * - Button press 'showWeekDate'.
 *
 * TO DO:
 * 
 */
 	showWeekDate() {

        const todayDate = new Date();
        const pastWeekDate = new Date();
        
        pastWeekDate.setDate(todayDate.getDate() - 6);
		
		//Sets the value of variables in YYYY-MM-DD format.
        this.selectStartDate = pastWeekDate.toISOString().split('T')[0];
        this.selectEndDate = todayDate.toISOString().split('T')[0];

		this.selectMode = 'Week';
        this.responsiveLineWidth = this.mediumLineWidth;

        this.fetchEnergyPrices();
    },



/** showMonthDate()
 *
 * Functionality:
 * - The function sets the selectStartDate to 29 days before todays date and selectEndDate 
 *   to todays date in  a YYYY-MM-DD format to prepare it for the fetch function that 
 *   it calls at the end.
 * - Sets the Chart line width and sets the selected mode
 *
 * Input:

 * 
 * Output:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - selectMode (str).
 * - responsiveLineWidth (num).
 * - fetchEnergyPrices().
 * 
 * Trigger:
 * - Button press 'showMonthDate'.
 *
 * TO DO:
 * 
 */
	showMonthDate() {

		const todayDate = new Date();
		const pastMonthDate = new Date();

		pastMonthDate.setDate(todayDate.getDate() - 29);

		//Sets the value of variables in YYYY-MM-DD format.
		this.selectStartDate = pastMonthDate.toISOString().split('T')[0];
		this.selectEndDate = todayDate.toISOString().split('T')[0];

		this.selectMode = 'Month';
		this.responsiveLineWidth = this.smallLineWidth;

		this.fetchEnergyPrices();
	},



/** decreaseDate()
 *
 * Functionality:
 * - The function sets the selectStartDate and selectEndDate based on the selectDate.
 *   For Today by 1, for Week by 7 and for Month by 30.
 *   it calls at the end.
 * - Sets the Chart line width based on selectMode.
 *
 * Input:
 * 
 * Output:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - responsiveLineWidth (num).
 * - fetchEnergyPrices().
 * 
 * Trigger:
 * - Button press '<' decrease.
 *
 * TO DO:
 * 
 */
	decreaseDate() {

    	const todayDate = new Date(this.selectEndDate);
    	const pastDate = new Date(this.selectStartDate);

    	if (this.selectMode === 'Today') {

        	todayDate.setDate(todayDate.getDate() - 1);

        	this.selectStartDate = todayDate.toISOString().split('T')[0];
        	this.selectEndDate = this.selectStartDate;

			this.responsiveLineWidth = this.regularLineWidth;

    	} else if (this.selectMode === 'Week') {

        	todayDate.setDate(todayDate.getDate() - 7);
        	pastDate.setDate(pastDate.getDate() - 7);

        	this.selectStartDate = pastDate.toISOString().split('T')[0];
			this.selectEndDate = todayDate.toISOString().split('T')[0];

			this.responsiveLineWidth = this.mediumLineWidth;

    	} else if (this.selectMode === 'Month') {

        	todayDate.setDate(todayDate.getDate() - 30);
        	pastDate.setDate(pastDate.getDate() - 30);

        	this.selectStartDate = pastDate.toISOString().split('T')[0];
			this.selectEndDate = todayDate.toISOString().split('T')[0];

			this.responsiveLineWidth = this.smallLineWidth;

    	}

        this.fetchEnergyPrices();
    },



/** increaseDate()
 *
 * Functionality:
 * - The function sets the selectStartDate and selectEndDate based on the selectDate.
 *   For Today by 1, for Week by 7 and for Month by 30.
 *   it calls at the end.
 * - Sets the Chart line width based on selectMode.
 *
 * Input:
 * 
 * Output:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - responsiveLineWidth (num).
 * - fetchEnergyPrices().
 * 
 * Trigger:
 * - Button press '>' increase.
 *
 * TO DO:
 * 
 */
	increaseDate() {

		const todayDate = new Date(this.selectEndDate);
		const pastDate = new Date(this.selectStartDate);

		if (this.selectMode === 'Today') {

			todayDate.setDate(todayDate.getDate() + 1);

			this.selectStartDate = todayDate.toISOString().split('T')[0];
			this.selectEndDate = this.selectStartDate;

			this.responsiveLineWidth = this.regularLineWidth;

		} else if (this.selectMode === 'Week') {

			todayDate.setDate(todayDate.getDate() + 7);
			pastDate.setDate(pastDate.getDate() + 7);

			this.selectStartDate = pastDate.toISOString().split('T')[0];
			this.selectEndDate = todayDate.toISOString().split('T')[0];

			this.responsiveLineWidth = this.mediumLineWidth;

		} else if (this.selectMode === 'Month') {

			todayDate.setDate(todayDate.getDate() + 30);
			pastDate.setDate(pastDate.getDate() + 30);

			this.selectStartDate = pastDate.toISOString().split('T')[0];
			this.selectEndDate = todayDate.toISOString().split('T')[0];

			this.responsiveLineWidth = this.smallLineWidth;

		}

		this.fetchEnergyPrices();
	},



/** fetchEnergyPrices()
 *
 * Functionality:
 * - Fetches the hourly energy price and the respected timestamp
 *   based on the selected date range.
 * - Checks for 500 response (no data available).
 * - Checks if there is valid data in the response
 * - 
 *
 * Input:
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * - selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 * 
 * Output:
 * - fetchedData (json): Fetched data, forwarded to handleFetchedPrices function.
 * - displaySyncState (str): Displays the fetch state and status to the user.
 * - Running of 'clearChartData()' in case of an error to clear the chart data.
 * 
 * Trigger:
 * - On component load.
 * - Button press 'showTodayDate'.
 *
 * Changelog:
 * -27.11.2024: Addition of error handling, and usage of Async Await 
 *  instead of Fetch.Then().
 * 
 * TO DO:
 * - 
*/
	async fetchEnergyPrices() {
		
		this.displaySyncState = 'Pridobivanje podatkov...';

		try {
			const energyChartsResponse = await fetch(`https://portal.server.kopower.si/fetch-energy-prices?start=${this.selectStartDate}&end=${this.selectEndDate}`,
			{ method: 'GET' });

			if (energyChartsResponse.status === 500) {
				console.warn("Data not available, ERR:500");
				this.displaySyncState = 'Podatki niso na voljo';

				this.clearChartData();
				return;
			}

			if (!energyChartsResponse.ok) {
				throw new Error(`HTTP new error! status: ${energyChartsResponse.status}`);
			}

			const fetchedData = await energyChartsResponse.json();

			if (!fetchedData || !fetchedData.price || !fetchedData.unix_seconds) {
				console.warn("No valid data returned from API");
				this.displaySyncState = 'Podatki niso na voljo';

				this.clearChartData();
				return;
			}

			//Response and data are OK, handle the recieved data
			this.handleFetchedPrices(fetchedData);

		} catch (error) {
			console.error("Error fetching data:", error);
			this.displaySyncState = 'Napaka pri pridobivanju podatkov';

			this.clearChartData();
		}
	},





/** handleFetchedPrices()
 *
 * Functionality:
 * - Assigns the parsedSelectedPrice with hourly energy prices.
 * - Assigns the parsedSelectedDate with UNIX timestamps.
 * - Updates the chart with the new data.
 * - Updates the displaySyncState timestamp for user display.
 *
 * Input:
 * - fetchedData (json): Fetched data, forwarded to handleFetchedPrices function.
 * 
 * Output:
 * - parsedSelectedDate (arr): Assigns it with hourly energy prices.
 * - parsedSelectedDate (arr): Assigns it with UNIX timestamps.
 * - displaySyncState (str): Assigns it with todays date in a format '20. 11. 2024, 14:46'
 * 
 * Trigger:
 * - From fetchEnergyPrices() after succesful fetching.
 *
 * TO DO:
 * - Error handling in case of bad data.
 * - Generation of diffrent x-axis ticks for Danes, 1T, 1M, 
 */
	handleFetchedPrices(fetchedData) {

		//Fill the arrays with price data and the coresponmding UNIX timestams
		this.parsedSelectedPrice = fetchedData.price;
		this.parsedSelectedDate = fetchedData.unix_seconds;

		
		// Generate ticks for X axis and update the chart
		this.xAxisTicks = this.customXAxisTicks();

		//Update the chart with new data
		if (this.chart) {
			this.chart.updateOptions({xaxis: {overwriteCategories: this.xAxisTicks,},});
			this.chart.updateOptions({stroke: {width: this.responsiveLineWidth,},});
			this.chart.updateSeries([{ data: this.parsedSelectedPrice }]);
		}
		

		//Updates the sync indicator to current date and time
		const now = new Date();
		this.displaySyncState = now.toLocaleDateString('sl-SI', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			timeZone: 'Europe/Ljubljana',	
		});
	},




	
/** clearChartData()
 *
 * Functionality:
 * - The function is called after a bad fetch request or invadil recieved data.
 * - Clears the 'parsedSelectedPrice' and 'parsedSelectedDate'
 * - Loads the chart with empty data.
 * 
 * Input:
 * 
 * Output:
 * 
 * Trigger:
 * - From fetchEnergyPrices() in case of bad fetching or invalid data.
*/
	clearChartData() {
		this.parsedSelectedPrice = [];
		this.parsedSelectedDate = [];
		this.SteplineChart();
	},





/** customXAxisTicks()
 *
 * Functionality:
 * - Creates custom ticks for the X axis based on the selected date.
 * - The function first converts UNIX timestamps recieved from the 'handleFetchedPrices'.
 * - Based on the 'selectMode' populates the 'overwrittenCategories'.
 * - For Today it populates 'overwrittenCategories' with numbers from 1-24, for each hour.
 * - For Today and Month it populates 'overwrittenCategories' with four timestamps, 
 *   equally spaced apart from each other in a '26.11' format.
 *
 * Input:
 * - parsedSelectedDate (arr): UNIX timestamps for each hour of energy prices.
 * 
 * Output:
 * - overwrittenCategories (arr of str): Arraay of 24 or 4 timestamps, depending on the selectMode.
 * 
 * Trigger:
 * - From handleFetchedPrices().
 *
 * TO DO:
 * -
 * 
 * OTHER INFORMATION:
 * - The 'year' of the UNIX forrmating ic commented, because the last timestampl is too long for
 *   it to be displayed niceli, without increasing the right padding of the chart.
 */
	customXAxisTicks() {

		let overwrittenCategories = [];

		//Convert UNIX timestamps to "02.01." fromat.
  		const formattedDates = this.parsedSelectedDate.map((unix) =>

			new Date(unix * 1000).toLocaleDateString('sl-SI', {
				day: '2-digit',
				month: '2-digit',
				//year: 'numeric',
				})
		);


		if (this.selectMode === 'Today') {

			//Set categories to 1-24 for each hour of the day.
			overwrittenCategories = Array.from({ length: 24 }, (_, i) => `${i + 1}`);

		} else if (this.selectMode === 'Week') {

			//Set categories to four timestamps  
			const firstDate = formattedDates[0];
			const oneThird = formattedDates[Math.floor (formattedDates.length / 3)];
			const twoThird = formattedDates[Math.floor ((2 * formattedDates.length) / 3)];
			const lastDate = formattedDates[formattedDates.length - 1];

			overwrittenCategories = [firstDate, oneThird, twoThird, lastDate];

		} else if (this.selectMode === 'Month') {

			//Set categories to four timestamps  
			const firstDate = formattedDates[0];
			const oneThird = formattedDates[Math.floor (formattedDates.length / 3)];
			const twoThird = formattedDates[Math.floor ((2 * formattedDates.length) / 3)];
			const lastDate = formattedDates[formattedDates.length - 1];

			overwrittenCategories = [firstDate, oneThird, twoThird, lastDate];

		}
		return overwrittenCategories;
	},



/** customTooltip()
 * 
 * Functionality:
 * -Creates a custom tooltip for the chart points, displaying date, time and price 
 *  of the hovered over point.
 * - The function first checks if there is valid data in parsedSelectedDate'.
 * - Then converts the UNIX seconds into date format.
 * - Formats the data into the desired format (day, weekday, timeFrom, timeUntill).
 * - Searches for the hovered over electricity price.
 * 
 * Input:
 * - series (arr): Array containing data series.
 * - seriesIndex (int): Index of the series being hovered over.
 * - dataPointIndex (int): Index of the specific data point in the series.
 * 
 * Output:
 * -HTML code, for displaying the custom tooltip, including 'day', 'weekday', 'timeFrom' 'timeUntil'
 * 
 * Trigger:
 * -Hovering over chart data points.
 * 
 * TO DO:
 * -
 */
  customTooltip({ series, seriesIndex, dataPointIndex }) {

    // Check if data exists
    if (!series[seriesIndex] || series[seriesIndex][dataPointIndex] === undefined || !this.parsedSelectedDate[dataPointIndex]) {
      	
		return `
        <div style="background: #fff; padding: 5px; border: 1px solid #ccc; border-radius: 4px;">
          No data available
        </div>`;
    }

	//Converting UNIX seconds into date format
    const date = new Date(this.parsedSelectedDate[dataPointIndex] * 1000);

	
	const nextHour = new Date(date.getTime() + 60 * 60 * 1000);

    // Formatting date and time
    const day = date.toLocaleDateString('sl-SI', {
		 day: '2-digit', 
		 month: '2-digit' });

    const weekdayWord = date.toLocaleDateString('sl-SI', {
		 weekday: 'long' });

    const timeFrom = date.toLocaleTimeString('sl-SI', {
		 hour: '2-digit', 
		 minute: '2-digit' });

    const timeUntil = nextHour.toLocaleTimeString('sl-SI', { 
		hour: '2-digit', 
		minute: '2-digit' });


	//Accesses the hourly price being hovered at
    const priceValue = series[seriesIndex][dataPointIndex];

    return `
      <div style="background: #fff; padding: 5px; border: 1px solid #ccc; border-radius: 4px;">
        <strong>${day}, ${weekdayWord}, ${timeFrom} - ${timeUntil}</strong>
		<br/>
        <hr>
        Cena [EUR/MWh]: ${priceValue.toFixed(2)}
      </div>`;
  },

},





//created: Used for functions to execute after the component initialized.
created() {

	//Sets the following variables to todays date, for the initial load up of the site. 
	const today = new Date().toISOString().slice(0, 10);
	this.selectStartDate = today;
	this.selectEndDate = today;

},





//mounted: Used for functions to execute after the component is loaded into DOM.
mounted() {

	this.SteplineChart();
	this.showTodayDate();

},





//computed: Dynamically executes functions if the dependencies change.
computed:{


/** displayStartDate()
 *
 * Functionality:
 * -It updates the displayed start date of the set selectStartDate to a user
 *  understandable date format 'DD.MM' instead of the 'YYYY-MM-DD'.
 *
 * Input:
 * -selectStartDate (str): Start date in `YYYY-MM-DD` format selected by user.
 *
 * Output:
 * -displayStartDate (str): Start date in 'DD.MM' format.
 * 
 * Trigger:
 * - After change of 'selectStartDate' value.
 * 
 * TO DO:
 * - 
 */
	displayStartDate() {
	
		if (!this.selectStartDate) 

			return '';

			const date = new Date(this.selectStartDate);
			const day = date.getDate().toString().padStart(2, '0');
			const month = (date.getMonth() + 1).toString().padStart(2, '0');

			return `${day}.${month}`;
	},
	


/** displayEndDate()
 *
 * Functionality:
 * -It updates the displayed end date of the set selectEndDate to a user
 *  understandable date format 'DD.MM.YYYY' instead of the 'YYYY-MM-DD'.
 *
 * Input:
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 *
 * Output:
 * -displayEndDate (str): End date in 'DD.MM.YYYY' format.
 * 
 * Trigger:
 * - After change of 'selectEndDate' value.
 * 
 * TO DO:
 * - 
 */
	displayEndDate() {

		if (!this.selectEndDate) 
			
			return '';

			const date = new Date(this.selectEndDate);
			const day = date.getDate().toString().padStart(2, '0');
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const year = date.getFullYear();

			return `${day}.${month}.${year}`;
		},


/** increaseBtnVisibiliy()
 *
 * Functionality:
 * - It hides/shows the increase date button, to prevernt the user from setting the date range
 *   further than the tommorows date.
 * - If the 'selectMode' is Week or Month and the selected Date is today or more the button is hidden.
 * - If the 'selectMode' is Today and the selected Date is less than tommorow, the button is visible.
 * 
 * Input:
 * - selectEndDate (str): End date in `YYYY-MM-DD` format selected by user.
 * - endDate (date): Set to midnight "T00:00:00".
 * - todayDate (date): Sets the current date and sets the time to midnight.
 * - tommorowDate (date): Sets the date to tommorow from the 'todayDate'.
 *
 * Output:
 * - Returns true/false for v-if="increaseBtnVisibiliy".
 * 
 * Trigger:
 * - After change of 'selectEndDate' value.
 * 
 * TO DO:
 * - 
 */
	increaseBtnVisibiliy() {

		const endDate = new Date(`${this.selectEndDate}T00:00:00`);
		
		const todayDate = new Date();
		todayDate.setHours(0, 0, 0, 0);

		const tomorrowDate = new Date(todayDate);
		tomorrowDate.setDate(
			todayDate.getDate() + 1);

		
		if ((this.selectMode === "Month" || this.selectMode === "Week") && endDate.getTime() >= todayDate.getTime()) {
			
			return false; 
		}

		if ((this.selectMode === "Today" )&& endDate.getTime() < tomorrowDate.getTime()) {

			return true ; 
		}

		// Default: Return true if endDate is before today
		return endDate.getTime() < todayDate.getTime();
	},

},

};
</script>